<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">
              Ticket Number : {{ detail.no_ticket }}
            </h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-12">
                <h5 class="border-bottom pb-2">
                  Check List {{ detail.type_ticket }} Maintenance
                </h5>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12 mb-2">
                    <label class="mb-0">Ticket Number :</label>
                    <div class="border-bottom pb-2" >
                      <strong>{{ detail.no_ticket }}</strong>&emsp;

                      <span v-if="detail.file !== null"  ><a class="btn btn-sm btn-primary" :href="detail.file" target="_blank"><i class="fas fa-file"></i> &nbsp;&nbsp; Open Attachment </a></span>
                    </div>
                    
                  </div>
                  <div class="col-md-6 mb-2">
                    <label class="mb-0">Issue :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.subject_ticket }}</strong>
                    </div>
                  </div>
                  <div class="col-md-6 mb-2">
                    <label class="mb-0">Detail Ticket :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.fill_ticket }}</strong>
                    </div>
                  </div>
                  <div class="col-md-12 mb-2" v-if="detail.nama_customer">
                    <label class="mb-0">Customer :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.nama_customer }}</strong>
                    </div>
                  </div>
                  <div class="col-md-6 mb-2">
                    <label class="mb-0">Date :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ dateFormat(detail.ticket_created) }}</strong>
                    </div>
                  </div>
                  <div class="col-md-6 mb-2">
                    <label class="mb-0">Team :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.nama_team }}</strong>
                    </div>
                  </div>
                  <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 mb-2"
                  :class="{
                  'col-md-12 mb-2':
                  !detail.ticket_progress,
                }">
                    <label class="mb-0">Ticket Status :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.status_ticket }}</strong>
                    </div>
                  </div>
                  <div v-if="detail.ticket_progress" class="col-md-6 mb-2">
                    <label class="mb-0">Ticket Progress :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.ticket_progress }}</strong>
                    </div>
                  </div>
                </div>
              </div>
                  <div v-if="detail.nama_area" class="col-md-4 mb-2">
                    <label class="mb-0">Area :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.nama_area }}</strong>
                    </div>
                  </div>
                  <div v-if="detail.nama_lokasi" class="col-md-4 mb-2">
                    <label class="mb-0">Location :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.nama_lokasi }}</strong>
                    </div>
                  </div>
                  <div v-if="detail.nama_sublokasi" class="col-md-4 mb-2">
                    <label class="mb-0">Sub Location :</label>
                    <div class="border-bottom pb-2">
                      <strong>{{ detail.nama_sublokasi }}</strong>
                    </div>
                  </div>
                </div>
              </div>
              
              
              <div class="col-md-12">
                <!-- <h3>Device</h3> -->

                <div class="card" v-if="detail.nama_device" >
                  <div class="card-body" >
                    <a href="#" class="btn btn-sm btn-primary" style="margin-bottom: 10px;">Device: {{ detail.nama_device }}</a>
                    <!-- <h5 class="card-title">Card title</h5> -->
                    <p v-if="detail.nama_brand" class="card-text" > Brand : <b>{{ detail.nama_brand }} </b></p>
                    <p v-if="detail.nama_type" class="card-text">Type : <b>{{ detail.nama_type }}</b></p>
                    <p v-if="detail.sn_device" class="card-text">SN : <b>{{ detail.sn_device }}</b></p>
                  </div>
                </div>
                <br v-if="detail.nama_device">

                <div class="form-group" v-if="detail.type_ticket !== 'Preventive'">
                  <label class="f-w-600" for="nama_device">Device :</label>
                  <select
                    v-model="form.id_device"
                    type="text"
                    class="form-control"
                    :disabled="detail.ticket_progress"
                   
                  >
                    <option value="" disabled>-Pilih-</option>
                    <option
                      v-for="device in listDevice"
                      :key="device.id_device"
                      :value="device.id_device"
                    >
                      {{ device.nama_device }}
                    </option>
                  </select>
                </div>

                <div class="form-group" v-if="detail.type_ticket == 'Preventive'">
                  <label class="f-w-600" for="method">Method </label>
                  <select
                    v-model="form.method"
                    type="text"
                    class="form-control"
                    :disabled="detail.ticket_progress"
                    @change="
                      changeMethod();
                    "
                  >
                    <option
                      :key="'ONSITE'"
                      :value="'ONSITE'"
                    >
                    ONSITE
                    </option>
                    <option
                      :key="'REMOTE'"
                      :value="'REMOTE'"
                    >
                    REMOTE
                    </option>
                    
                  </select>
                </div>
                </div>



                
                
            </div>
            <br>

            <div class="row">
              <div class="col-md-12" style="background: #00acac;color: #fff;">
                <h5 style="margin: 5px;">Item Maintenance</h5>
              </div>
              
              <div class="col-md-12">
                <br>
                <div
                  class="form-group"
                  v-for="(items, category) in groupedMaintenance"
                  :key="category"
                >
                  <label class="f-w-600">{{ category }}</label>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="widget-list widget-list-rounded mb-2">

                        
                        <a
                          v-for="item in items"
                          :key="item.id_maintenance"
                          href="javascript:;"
                          class="widget-list-item rounded-0 p-t-3"
                        >
                          <div
                            class="widget-list-media icon"
                            v-if="detail.ticket_progress"
                          >
                          
                            <span class="checkbox checkbox-css">
                              <input
                                type="checkbox"
                                :id="'cssCheckbox' + item.id_maintenance"
                                checked
                                disabled
                              />
                              <label :for="'cssCheckbox' + item.id_maintenance">
                                <span class="text-light">.</span>
                              </label>
                            </span>
                          </div>
                          <div class="widget-list-media icon" v-else>

                            
                            <span class="checkbox checkbox-css">
                              <input v-if="detail.type_ticket !== 'Preventive'"
                                type="checkbox"
                                :id="'cssCheckbox' + item.id_maintenance"
                                v-model="item.pilih"
                                @change="
                                  updateSelectedData(item)
                                "
                              />
                              <label :for="'cssCheckbox' + item.id_maintenance" v-if="detail.type_ticket !== 'Preventive'"> 
                                <span class="text-light">.</span>
                              </label>

                              <input v-if="detail.type_ticket == 'Preventive' && form.method == 'ONSITE'"
                                type="checkbox"
                                :id="'cssCheckbox2' + item.id_maintenance"
                                v-model="item.pilih"
                                @change="
                                  updateSelectedData(item)
                                "
                              />
                              <label :for="'cssCheckbox2' + item.id_maintenance" v-if="detail.type_ticket == 'Preventive' && form.method == 'ONSITE'">
                                <span class="text-light">.</span>
                              </label>


                            </span>
                          </div>
                          <label
                            class="widget-list-content" style="min-width: 80%"
                            :for="'cssCheckbox' + item.id_maintenance"
                          >
                            <div class="col-md-12">
                              <span>{{ item.item_maintenance }}</span>

                              
                              
                            </div>
                          </label>
                          <label v-if="detail.type_ticket == 'Preventive' && form.method == 'REMOTE'"
                          class="widget-list-media icon" style="width: 200px;text-align: right;"
                          >
                          <input 
                                v-model="item.value"
                                type="tel"
                                class="form-control col-md-12"
                                placeholder="Input Value"
                              />
                            </label>


                              <label
                                class="widget-list-media icon" style="width: 200px;text-align: right;"
                                v-if="userLevel == 'Koordinator' || userLevel == 'SPV' || userLevel == 'Administrator'"
                              >
                                <div class="col-md-12">
                                  <button
                                    type="button"
                                    class="btn btn-block btn-xs btn-primary"
                                    @click="
                                      triggerFileInputBefore(item.id_maintenance)
                                    "
                                  >
                                    Upload Image Before
                                  </button>
                                  <input
                                    type="file"
                                    :id="'fileInputBefore' + item.id_maintenance"
                                    class="d-none"
                                    @change="
                                      uploadImageBefore($event, item.id_maintenance, 0)
                                    "
                                  />
                                </div>
                                <div
                                  class="col-md-12"
                                  v-if="uploadProgress[item.id_maintenance]"
                                >
                                  <b-progress
                                    :value="
                                      uploadProgress[item.id_maintenance] || 0
                                    "
                                    :max="100"
                                    show-progress
                                    animated
                                  ></b-progress>
                                </div>
                                <div
                               class="col-md-12"
                              >
                                <div  v-if="item.image_check_before">
                                  <img
                                    class="border"
                                    :src="item.image_check_before"
                                    alt="image_check_before"
                                    style="width: 100%"
                                  />
                                </div>
                              </div>
                              </label>


                              <label
                                class="widget-list-media icon" style="width: 200px;text-align: right;"
                                v-if="userLevel == 'Koordinator' || userLevel == 'SPV' || userLevel == 'Administrator'"
                              >
                                <div class="col-md-12">
                                  <button
                                    type="button"
                                    class="btn btn-block btn-xs btn-primary"
                                    @click="
                                      triggerFileInput(item.id_maintenance)
                                    "
                                  >
                                    Upload Image After
                                  </button>
                                  <input
                                    type="file"
                                    :id="'fileInput' + item.id_maintenance"
                                    class="d-none"
                                    @change="
                                      uploadImage($event, item.id_maintenance)
                                    "
                                  />
                                </div>
                                <div
                                  class="col-md-12"
                                  v-if="uploadProgress[item.id_maintenance]"
                                >
                                  <b-progress
                                    :value="
                                      uploadProgress[item.id_maintenance] || 0
                                    "
                                    :max="100"
                                    show-progress
                                    animated
                                  ></b-progress>
                                </div>
                                <div
                               class="col-md-12"
                              >
                                <div  v-if="item.image_check">
                                  <img
                                    class="border"
                                    :src="item.image_check"
                                    alt="image_check"
                                    style="width: 100%"
                                  />
                                </div>
                              </div>
                              </label>
                        </a>
                        

                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="col-md-12" v-if="userLevel == 'Koordinator' || userLevel == 'SPV' || userLevel == 'Administrator'">
                <div class="form-group">
                  <label class="f-w-600" for="note_k">Note Kordinator : </label>
                  <textarea
                    v-model="form.note_k"
                    rows="2"
                    class="form-control"
                    placeholder="Note dari Kordinator"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-12" v-if="userLevel == 'SPV'">
                <div class="form-group">
                  <label class="f-w-600" for="note_k">Note SPV :</label>
                  <textarea
                    v-model="form.note_s"
                    rows="2"
                    class="form-control"
                    placeholder="Note dari SPV"
                  ></textarea>
                </div>
              </div>


             

            </div>
          </div>
          <div class="panel-footer">
            <div class="row">
              <div class="col-md-6 mb-2">
                <a
                  href="javascript:window.history.go(-1);"
                  class="btn btn-default"
                  >Kembali</a
                >
              </div>
              <div class="col-md-6">
                <button
                  v-if="!detail.ticket_progress"
                  class="btn btn-success float-md-right"
                  @click="postData"
                  variant="primary"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  Submit
                </button>
                <button
                  v-if="
                    detail.ticket_progress == 'Checked' &&
                    this.userLevel == 'Koordinator' 
                  "
                  class="btn btn-success float-md-right"
                  @click="verifyData"
                  variant="primary"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  Verify
                </button>
                <button
                  v-if="
                    detail.ticket_progress == 'Verify' &&
                    this.userLevel == 'SPV'
                  "
                  class="btn btn-success float-md-right"
                  @click="finishData"
                  variant="primary"
                  :disabled="loading"
                >
                  <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                  Finish
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import userData from "../../plugins/userData";
import moment from "moment";

export default {
  name: "FormProcess",
  mixins: [userData],
  data() {
    return {
      detail: {
        id_ticket: "",
        no_ticket: "",
        ticket_created: "",
        subject_ticket: "",
        fill_ticket: "",
        nama_device: "",
        type_ticket: "",
        ticket_closed: "",
        nama_area: "",
        nama_lokasi: "",
        nama_sublokasi: "",
        file: null,
      },
      form: {
        id_ticket: "",
        id_device: "",
        note_k: "",
        note_s: "",
        method: "ONSITE",
        ticket_progress: "Checked",
      },
      itemMaintenance: [],
      itemSearchMaintenance: [],
      dataDipilih: [],
      listDevice: [],
      loading: false,
      uploadProgress: {},
      uploadProgressBefore: {},
    };
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.form.id_ticket = id;
      this.getDetail(id);
    }
  },
  computed: {
    groupedMaintenance() {
      return this.itemMaintenance.reduce((groups, item) => {
        const category = item.nama_cat_maintenance;
        if (!groups[category]) {
          groups[category] = [];
        }
        groups[category].push(item);
        console.log('groups', groups)
        return groups;
      }, {});
    },
  },
  methods: {
    getDetail(id) {
      this.isLoading = true;
      axios
        .get(this.$url+"/v2/ticket/detail/" + id, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
          this.itemMaintenance = response.data.item_maintenance;
          this.itemSearchMaintenance = response.data.item_maintenance;

          if(this.detail.method ){
            this.form.method = this.detail.method;
          }

          if(this.detail.id_device ){
            this.form.id_device = this.detail.id_device;
          }

          if(this.detail.note_k ){
            this.form.note_k = this.detail.note_k;
          }

          if(this.detail.note_s ){
            this.form.note_s = this.detail.note_s;
          }

          console.log('--',this.itemMaintenance)

          if(this.detail.type_ticket == 'Preventive' && !this.detail.ticket_progress ){
                this.itemMaintenance = response.data.item_maintenance.filter(data => {
              return (data.type_formchecklist == this.form.method);
            });
          }
          console.log('--',this.itemMaintenance)
          this.getDevice();
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    changeMethod(){
      this.itemMaintenance = this.itemSearchMaintenance.filter(data => {
              return (data.type_formchecklist == this.form.method);
            });


    },
    getDevice() {
      if (this.detail.id_sublokasi) {
        axios
          .get(this.$url+"/v2/device/option/" + this.detail.id_sublokasi)
          .then((response) => {
            this.listDevice = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    postData() {
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      if(this.detail.type_ticket== 'Preventive' && this.form.method == 'REMOTE'){
        const check = this.itemMaintenance.filter(data => {
          return (data.value == '');
        });
        if(check.length > 0){
            // alert("Item maintenance any empty !");
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Item maintenance remote any value is empty !",
              });

            return false;
        }
        this.dataDipilih = this.itemMaintenance; 
      }

      if(this.dataDipilih.length == 0){
        Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Item maintenance is empty !",
              });

            return false;
      }
      this.loading = true;
      console.log(this.dataDipilih)
      
      formData.append("list_checked", JSON.stringify(this.dataDipilih));
      axios
        .post(this.$url+"/v2/ticket/process/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Input form tidak lengkap !",
              });
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    verifyData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post(this.$url+"/v2/ticket/verify/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Input form tidak lengkap !",
              });
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    finishData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post(this.$url+"/v2/ticket/finish/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Input form tidak lengkap !",
              });
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
    updateSelectedData(obj) {
      console.log(obj);

      const maintenance = this.itemMaintenance.find(
        (item) => item.id_maintenance === obj.id_maintenance
      );
      if (maintenance) {
        if (maintenance.pilih) {
          this.dataDipilih.push(obj);
        } else {
          const indexToRemove = this.dataDipilih.findIndex(
            (item) => item.id_maintenance === obj.id_maintenance
          );
          if (indexToRemove !== -1) {
            this.dataDipilih.splice(indexToRemove, 1);
          }
        }
      }

      console.log(this.dataDipilih);
    },
    triggerFileInputBefore(id) {
      document.getElementById("fileInputBefore" + id).click();
    },
    triggerFileInput(id) {
      document.getElementById("fileInput" + id).click();
    },
    uploadImageBefore(event, id) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("id", id);
        formData.append("kode", this.detail.no_ticket);
        formData.append("ticket", this.detail.id_ticket);

        // Kirim request dengan Axios
        axios
          .post(this.$url+"/v2/ticket/upload_image_before", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              token: this.userToken,
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              // Perbarui progress untuk item yang sesuai
              this.$set(this.uploadProgressBefore, id, percentCompleted);
            },
          })
          .then((response) => {
            console.log(response);
            this.uploadProgressBefore = {};
            this.getDetail(this.form.id_ticket);
            // Sukses: Tindakan setelah berhasil mengunggah
            // alert("Image uploaded successfully for item: " + id);
          })
          .catch((error) => {
            // Error: Tindakan jika terjadi kesalahan
            console.error("Error uploading image:", error);
          });
      }
    },
    uploadImage(event, id) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("id", id);
        formData.append("kode", this.detail.no_ticket);
        formData.append("ticket", this.detail.id_ticket);

        // Kirim request dengan Axios
        axios
          .post(this.$url+"/v2/ticket/upload_image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              token: this.userToken,
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              // Perbarui progress untuk item yang sesuai
              this.$set(this.uploadProgress, id, percentCompleted);
            },
          })
          .then((response) => {
            console.log(response);
            this.uploadProgress = {};
            this.getDetail(this.form.id_ticket);
            // Sukses: Tindakan setelah berhasil mengunggah
            // alert("Image uploaded successfully for item: " + id);
          })
          .catch((error) => {
            // Error: Tindakan jika terjadi kesalahan
            console.error("Error uploading image:", error);
          });
      }
    },
    dateFormat(dateTime) {
      if (dateTime) {
        return moment(dateTime).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    },
    timeFormat(dateTime) {
      if (dateTime) {
        return moment(dateTime).format("H:m");
      } else {
        return "-";
      }
    },
    datetimeFormat(dateTime) {
      if (dateTime) {
        return moment(dateTime).format("DD/MM/YYYY HH:mm");
      } else {
        return "-";
      }
    },
    calculateDuration(start, end) {
      if (end) {
        const date1 = moment(start);
        const date2 = moment(end);

        const diff = moment.duration(date2.diff(date1));
        const totalHours = Math.floor(diff.asHours());
        const minutes = diff.minutes();

        return `${totalHours}:${minutes}`;
      } else {
        return "Not Finish";
      }
    },
  },
};
</script>